<template>
  <div class="detailbg">
    <div style="padding-bottom: 80px">
      <div>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in imgs" :key="item">
            <img :src="item" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div>
        <div>
          <div style="margin: 10px 15px; font-size: 18px">
            {{ list.Name }}
          </div>
          <div class="detailsB">
            <span style="">公司行业: </span>
            <span
              style="padding-right: 5px; color: #188dfa"
              v-for="item in list.ClassifyName"
              :key="item"
              >{{ item }}</span
            >
            <!-- <span style="margin-left: 4px">互联网科技公司</span> -->
          </div>
        </div>
        <div class="marginTop">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 10px;
            "
          >
            公司简介
          </div>
          <div style="margin: 5px 15px; font-size: 14px">
            {{ list.Summary }}
          </div>
        </div>
        <div class="marginTop">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司规模
          </div>
          <div style="margin: 5px 15px; font-size: 14px">
            <div>
              <span style="color: #999999">注册资本</span>
              <span style="padding-left: 15px">{{ list.RegCapital }}</span>
            </div>
            <div>
              <span style="color: #999999">成立日期</span>
              <span style="padding-left: 15px">{{ list.FoundDate }}</span>
            </div>
            <div>
              <span style="color: #999999">公司规模</span>
              <span style="padding-left: 15px">{{ list.Scale }}</span>
            </div>
          </div>
        </div>
        <div class="marginTop">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司联系方式
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div>
              <span style="color: #999999">负责人</span>
              <span style="padding-left: 15px">{{ list.Lliabler }}</span>
            </div>
            <div style="margin-top: 6px">
              <span style="color: #999999">联系电话</span>
              <span style="padding-left: 15px">{{ list.Mobile }}</span>
            </div>
            <div style="margin-top: 6px" v-if="list.Tel">
              <span style="color: #999999">固定电话</span>
              <span style="padding-left: 15px">{{ list.Tel }}</span>
            </div>
            <div style="margin-top: 6px" v-if="list.Email">
              <span style="color: #999999">企业邮箱</span>
              <span style="padding-left: 15px">{{ list.Email }}</span>
            </div>
            <div style="margin-top: 6px" v-if="list.Website">
              <span style="color: #999999">企业官网</span>
              <span style="padding-left: 15px">{{ list.Website }}</span>
            </div>
          </div>
        </div>
        <div class="marginTop" v-if="list.SucCase">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司成功项目
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div>
              <!-- <span style="color: #999999">智慧社区管理平台</span> -->
              <div style="font-size: 14px; margin: 5px 0px">
                {{ list.SucCase }}
              </div>
            </div>
          </div>
        </div>
        <div class="marginTop">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            公司地址
          </div>
          <div style="margin: 10px 15px; position: relative">
            <div style="width:80%">
              <span style="font-size: 14px;">{{ list.Address }}</span>
              <br />
              <!-- <span style="font-size: 13px; color: #a3a3a3"></span> -->
            </div>
            <div class="detailsC">
              <!-- <span style="color: #ff5050; font-size: 22px; margin-right: 15px">
                <van-icon name="location"/>
              </span> -->
              <span style="color: #ff5050; font-size: 22px; margin-left: 15px" >
                <a :href="'tel:' + list.Mobile" style="color: #ff5050;"><van-icon name="phone"/></a> 
              </span>
            </div>
          </div>
        </div>
        <div v-if="commentCount != 0">
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 5px;
            "
          >
            评价({{ commentCount }})
          </div>
          <div style="margin: 10px 15px; font-size: 15px">
            <div v-for="item in commentList" :key="item">
              <span v-if="item.StarLevel == 1" style="color:#9ed958">一星评价</span>
              <span v-if="item.StarLevel == 2" style="color:#ffeb3c">二星评价</span>
              <span v-if="item.StarLevel == 3" style="color:#ffd507">三星评价</span>
              <span v-if="item.StarLevel == 4" style="color:#ff9801">四星评价</span>
              <span v-if="item.StarLevel == 5" style="color:#ff5821">五星评价</span>
              <div style="font-size: 14px; margin: 5px 0px">
                {{ item.Content }}<span style="color: #999999;font-size:12px;padding-left:15px">{{ item.AssTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detailsD">
      <div class="detailsDs">
        <button @click="popupshow = true">服务清单</button>
      </div>
    </div>
    <van-popup
      v-model="popupshow"
      round
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div class="fuwu_box" v-for="item in serveList" :key="item.Title">
        <div style="padding-top: 10px" class="clearfix">
          <span style="float:left">{{ item.Title }}</span>
          <span style="float: right;color: red">{{ item.Charges }}</span>
        </div>
        <div style="font-size: 10px; color: #2196f3">{{ item.ModeDesc }} <span style="padding-left:10px">服务时间:{{item.Duration}}</span></div>
        <div class="clearfix" style="font-size: 14px">{{ item.Content }}</div>
      </div>
       <van-empty description="暂无服务" v-if="!serveList.length" />
    </van-popup>
  </div>
</template>
<script>
import {
  WeGetAgency,
  WeGetAgencyServiceList,
  WeGetAssessAgencyPage,
} from "@/api/Hyapi";
export default {
  data() {
    return {
      popupshow: false,
      list: {}, //详情内容
      dataFrom: {
        page: 1,
        limit: 10,
        aId: undefined,
      },
      commentList: {}, //评论列表
      commentCount: "", //评论条数
      serveList: {}, //服务列表
      serveCount: "", //服务条数
      imgs:[],
    };
  },
  methods: {
    // 获取机构详情
    weGetAgency() {
      this.dataFrom.aId = this.$route.params.Id;
      WeGetAgency({ aId: this.dataFrom.aId }).then((res) => {
        this.list = res.data.data[0];
        var classArr = this.list.ClassifyName.split(",");
        this.list.ClassifyName = classArr;
        this.imgs = this.list.Imgs.split(",");
      });
    },
    // 获取评价列表
    GetAssessAgencyPage() {
      WeGetAssessAgencyPage(this.dataFrom).then((res) => {
        this.commentList = res.data.data;
        for (let i = 0; i < this.commentList.length; i++) {
          console.log(this.commentList[i].AssTime);
          this.commentList[i].AssTime = this.dataFormat(this.commentList[i].AssTime);
        }
        this.commentCount = res.data.count;
      });
    },
    // 获取评价列表
    GetAgencyServiceList() {
      WeGetAgencyServiceList({ aId: this.dataFrom.aId }).then((res) => {
        this.serveList = res.data.data;
        this.serveCount = res.data.count;
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.weGetAgency();
    this.GetAssessAgencyPage();
    this.GetAgencyServiceList();
  },
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.marginTop {
  margin: 25px 0px;
}
.detailsA {
  position: relative;
  margin: 10px 15px;
}
.detailsA span:nth-child(1) {
  color: #fb4e44;
  font-size: 24px;
}
.detailsA span:nth-child(2) {
  padding-left: 15px;
  font-size: 14px;
  color: #ccc;
}
.detailsA span:nth-child(3) {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 14px;
}
.detailsB {
  display: flex;
  align-items: center;
  margin: 5px 15px;
  color: #666666;
  font-size: 14px;
}
.detailbg {
  background: #fff;
}
.detailsC {
  position: absolute;
  right: 25px;
  top: 7px;
}
.detailsD {
  position: fixed;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: #fafafa;
  border-top: 1px solid #ccc;
}
.detailsDs span {
  line-height: 50px;
  color: #fb4e44;
  margin: 0px 15px;
}
.detailsDs button {
  position: absolute;
  right: 0px;
  width: 120px;
  line-height: 50px;
  color: #fff;
  background: #fb4e44;
  border: none;
}
.fuwu_box {
  width: 90%;
  margin: 10px 5%;
  background: #fafafa;
  border-radius: 5px;
}
.fuwu_box div {
  padding: 0px 10px 8px 15px;
}
</style>